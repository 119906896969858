<template>
  <div class="form__item">
    <slot name="label">
      <label v-if="label" class="form__label">
        {{ label }}
        <i
          v-if="info"
          class="bi bi-info-circle-fill"
          data-toggle="tooltip"
          data-placement="top"
          style="font-size: 1.6rem"
          :title="info"
        ></i>
      </label>
    </slot>
    <slot name="input">
      <input
        :value="value"
        v-bind="$attrs"
        v-on="listeners"
        :maxlength="maxLength"
        :ref="myRef"
        @input="$emit('input', $event.target.value)"
        @focus="$emit('focus', $event)"
        @keyup.delete="$emit('delete-or-backspace-key-pressed')"
        class="input form__input form__input--lg"
        :class="className"
        :style="showRedBorder ? 'border: 2px solid red !important' : ''"
      />
    </slot>
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      description: "Input label",
    },
    myRef: {
      type: String,
    },
    value: {
      type: [String, Number, Array],
      description: "Input value",
    },
    autoFocus: {
      type: [Boolean],
    },
    className: {
      type: String,
    },
    maxLength: {
      type: Number,
    },
    showRedBorder: {
      type: Boolean,
      default: false,
    },
    info: {
      type: String,
      default: null,
    }
  },

  model: {
    prop: "value",
    event: "input",
  },

  data() {
    return {
      focused: false,
    };
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus,
      };
    },
  },

  methods: {
    onInput(evt) {
      this.$emit("input", evt.target.value);
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
  },

  watch: {
    autoFocus(newValue) {
      // set focus index of first otp input field to 1 when it changes.
      if (newValue) {
        // this.$refs.input.focus();
        this.onFocus();
      }
    },
  },
  mounted() {
    if (this.myRef) this.$refs[this.myRef].focus();
  },
};
</script>
<style lang="scss" scoped>
.form-input-input {
  height: 65px !important;
  font-size: 1.5rem !important;
}
</style>
